const assets = {
  images: {
    //world map
    worldmap: require("../../assets/Media/worldmap.svg"),
    contactBottomImage: require("../../assets/contactuspage/graphic-element-bottom.svg"),
    updaptNewLogo: require("../../assets/Header/newLogo.svg"),
    updaptLogoGreen: require("../../assets/Header/logo-green.svg"),
    updaptLogoWhite: require("../../assets/Header/logo-white.svg"),
    dataTrackImage1: require("../../assets/Solution/datatrack-images/1.svg"),
    dataTrackImage2: require("../../assets/Solution/datatrack-images/2.svg"),
    dataTrackImage3: require("../../assets/Solution/datatrack-images/3.svg"),
    dataTrackImage4: require("../../assets/Solution/datatrack-images/4.svg"),
    dataTrackImage5: require("../../assets/Solution/datatrack-images/5.svg"),
    analyticsImage1: require("../../assets/Solution/analytics-images/1.svg"),
    analyticsImage2: require("../../assets/Solution/analytics-images/2.svg"),
    analyticsImage3: require("../../assets/Solution/analytics-images/3.svg"),
    analyticsImage4: require("../../assets/Solution/analytics-images/4.svg"),
    reportingImage1: require("../../assets/Solution/reporting-images/1.svg"),
    reportingImage2: require("../../assets/Solution/reporting-images/2.svg"),
    reportingImage3: require("../../assets/Solution/reporting-images/3.svg"),
    reportingImage4: require("../../assets/Solution/reporting-images/4.svg"),
    nzImage3: require("../../assets/Solution/nz-images/3.svg"),
    nzImage1: require("../../assets/Solution/nz-images/1.svg"),
    nzImage2: require("../../assets/Solution/nz-images/2.svg"),
    ermImage1: require("../../assets/Solution/erm-images/1.svg"),
    ermImage2: require("../../assets/Solution/erm-images/2.svg"),
    ermImage3: require("../../assets/Solution/erm-images/3.svg"),
    ermImage4: require("../../assets/Solution/erm-images/4.svg"),
    ermImage5: require("../../assets/Solution/erm-images/5.svg"),
    scmImage1: require("../../assets/Solution/scm-images/1.svg"),
    scmImage2: require("../../assets/Solution/scm-images/2.svg"),
    scmImage3: require("../../assets/Solution/scm-images/3.svg"),
    scmImage4: require("../../assets/Solution/scm-images/4.svg"),
    scmImage5: require("../../assets/Solution/scm-images/5.svg"),
    paImage1: require("../../assets/Solution/pa-images/1.svg"),
    paImage2: require("../../assets/Solution/pa-images/2.svg"),
    paImage3: require("../../assets/Solution/pa-images/3.svg"),
    paImage4: require("../../assets/Solution/pa-images/4.svg"),
    paImage5: require("../../assets/Solution/pa-images/5.svg"),
    footerDemoBackground: require("../../assets/FooterImages/demo-background.svg"),
    homeBannerImage: require("../../assets/Home/banner.svg"),
    homeBanner1: require("../../assets/Home/Banner/1.svg"),
    homeBanner2: require("../../assets/Home/Banner/2.svg"),
    homeBanner3: require("../../assets/Home/Banner/3.svg"),
    homeBanner4: require("../../assets/Home/Banner/4.svg"),
    homeBanner5: require("../../assets/Home/Banner/5.svg"),
    homeSecurityImage: require("../../assets/Home/security.svg"),
    homeBannerImageDot: require("../../assets/Home/banner-dot.svg"),
    homeDatatrackImage: require("../../assets/Home/datatrack.svg"),
    homeAnalyticsImage: require("../../assets/Home/analytics.svg"),
    reportinHomePageBanner: require("../../assets/Home/reportinHomePageBanner.svg"),
    accentureLogo: require("../../assets/Home/accenture__ciie.svg"),
    profile: require("../../assets/Home/profile.svg"),
    img2: require("../../assets/Home/img2.png").default,
    backArrow: require("../../assets/Home/back-arrow.svg"),
    grayBackArrow: require("../../assets/Home/gray-back-arrow.svg"),
    grayNextArrow: require("../../assets/Home/gray-next-button.svg"),
    nextbutton: require("../../assets/Home/nextButton.svg"),
    quote: require("../../assets/Home/quote-icon.svg"),
    blogsPageImage: require("../../assets/Blogs/letterbox.svg"),
    firstImage: require("../../assets/icons/Capacity-management.svg"),
    secondImage: require("../../assets/icons/Data-backup.svg"),
    thirdImage: require("../../assets/icons/Encryption.svg"),
    fourthImage: require("../../assets/icons/Vitual-Private-Cloud.svg"),
    mithunPic: require("../../assets/aboutuspage/MithunSuvarna.svg"),
    satishPic: require("../../assets/aboutuspage/SatishRamchandani.svg"),
    sangathPic: require("../../assets/aboutuspage/Sangath.svg"),
    supreethPic: require("../../assets/aboutuspage/SupreethRao.svg"),
    shaileshPic: require("../../assets/aboutuspage/Shailesh.svg"),
    ericSolheimPic: require("../../assets/aboutuspage/EricSolheim.svg"),
    vineetAgarwal: require("../../assets/aboutuspage/vineetAgarwal.svg"),
    maryamTelmesani: require("../../assets/aboutuspage/maryamTelmesani.svg"),
    sanmiO: require("../../assets/aboutuspage/sanmiO.svg"),
    chintanPic: require("../../assets/aboutuspage/Chinthan.svg"),
    divyaPic: require("../../assets/aboutuspage/DivyashreeC.svg"),
    nitheshPic: require("../../assets/aboutuspage/NitheshPadival.svg"),
    sapthamPic: require("../../assets/aboutuspage/Saptham.svg"),
    // ankithaPic: require("../../assets/aboutuspage/AnkithaB.svg"),
    sathwikPic: require("../../assets/aboutuspage/SathwikRai.svg"),
    aa100Image: require("../../assets/licencing/aa1000.svg"),
    globalImage: require("../../assets/licencing/global-goals.svg"),
    unitedImage: require("../../assets/licencing/united-nations.svg"),
    wwfImage: require("../../assets/licencing/wwf.svg"),
    cdpImage: require("../../assets/licencing/cdp.svg"),
    sasbImage: require("../../assets/licencing/sasb.svg"),
    griImage: require("../../assets/licencing/gri.svg"),
    priImage: require("../../assets/licencing/pri.svg"),
    user: require("../../assets/images/unkownUser.svg"),
    // pricol: require("../../assets/images/pricolImg.svg"),
    pricolIcon: require("../../assets/images/pricolIcon.png"),
    careersBanner: require("../../assets/images/careersBanner.svg"),
    careerCollage: require("../../assets/images/careerCollage.svg"),
    tataPowerTestimonialIcon: require("../../assets/Home/tataPowerTestimonialIcon.svg"),
    apolloHospitalsTestimonialIcon: require("../../assets/Home/apolloHospitalsTestimonialIcon.svg"),

    //partner logos
    accenture: require("../../assets/images/accenture.svg"),
    ais: require("../../assets/images/ais.png"),
    blueOceanSystems: require("../../assets/images/blueOceanSystems.png"),
    blueSky: require("../../assets/images/blueSky.png"),
    dmWorld: require("../../assets/images/dmWorld.png"),
    dnv: require("../../assets/images/dnv.svg"),
    earthood: require("../../assets/images/earthood.png"),
    empoweredAnalytics: require("../../assets/images/empoweredAnalytics.png"),
    greenBeeEnergy: require("../../assets/images/greenBeeEnergy.png"),
    hcl: require("../../assets/images/hcl.svg"),
    imss: require("../../assets/images/imss.png"),
    ifcom: require("../../assets/images/ifcom.jpg"),
    itqan: require("../../assets/images/itqan.png"),
    pccs: require("../../assets/images/pccs.png"),
    pgso: require("../../assets/images/pgso.png"),
    raqeeb: require("../../assets/images/raqeeb.png"),
    riskPro: require("../../assets/images/riskPro.png"),
    rsm: require("../../assets/images/rsm.png"),
    suraksha: require("../../assets/images/suraksha.png"),
    thinkPlus: require("../../assets/images/thinkPlus.png"),
    zest: require("../../assets/images/zest.png"),
    lybra: require("../../assets/images/lybra.svg"),
    nethronix: require("../../assets/images/nethronix.svg"),
    aarksee: require("../../assets/images/aarksee.jpg"),
    digeto: require("../../assets/images/digeto.png"),
    grantThornton: require("../../assets/images/grantThornton.jpg"),
    teknoSpec: require("../../assets/images/teknoSpec.png"),
    lithe: require("../../assets/images/lithe.jpeg"),
    scribe: require("../../assets/images/scribe.png"),
    skyMax: require("../../assets/images/skyMax.jpg"),
    greenConsults: require("../../assets/images/greenConsults.jpg"),
    vDimension: require("../../assets/images/vDimension.jpeg"),

    //log0's
    cipla_grey: require("../../assets/images/1_cipla.svg"),
    tata_power_grey: require("../../assets/images/2_tatapower.svg"),
    licious_grey: require("../../assets/images/3_licious.svg"),
    // apollo: require("../../assets/images/4_apollo.svg"),
    cochin_shipyard_grey: require("../../assets/images/5_cochinshipyard.svg"),
    pricol_grey: require("../../assets/images/6_pricol.svg"),
    everstone_grey: require("../../assets/images/7_everstone.svg"),
    sahyadri_hospials_grey: require("../../assets/images/10_sahyadrihospitals.svg"),
    omega_healthcare_grey: require("../../assets/images/11_omh_omega.svg"),
    calibre_grey: require("../../assets/images/12_calibre.svg"),
    chemopharm_grey: require("../../assets/images/13_chemopharm.svg"),
    cpc_grey: require("../../assets/images/14_cpc.svg"),
    asia: require("../../assets/images/15_fandbasia.svg"),
    harrys_grey: require("../../assets/images/16_harrys.svg"),
    burgerking_grey: require("../../assets/images/17_burgerking.svg"),
    partnership: require("../../assets/images/partnership.svg"),
    dm_world: require("../../assets/images/dm_world.svg"),
    greenbee: require("../../assets/images/greenbee.svg"),
    oizom: require("../../assets/images/oizom.svg"),
    // raqeeb: require("../../assets/images/raqeeb.svg"),
    ecolibrium: require("../../assets/images/ecolibrium.svg"),
    // thinkplus: require("../../assets/images/thinkplus.svg"),
    lhs_circle: require("../../assets/images/lhs_circle.svg"),
    rhs_circle: require("../../assets/images/rhs_circle.svg"),
    // dnv: require("../../assets/images/dnv.svg"),
    // suraksha: require("../../assets/images/suraksha.svg"),

    //company clients
    cipla: require("../../assets/images/clients/1_cipla.svg"),
    tata: require("../../assets/images/clients/2_tata.svg"),
    apollo: require("../../assets/images/clients/3_apollo.svg"),
    pricol: require("../../assets/images/clients/4_pricol.svg"),
    cochin: require("../../assets/images/clients/5_cochin.svg"),
    licious: require("../../assets/images/clients/6_licious.svg"),
    omega: require("../../assets/images/clients/8_omega.svg"),
    cookieman: require("../../assets/images/clients/9_cookieman.svg"),
    dsg: require("../../assets/images/clients/11_dsg.svg"),
    burger: require("../../assets/images/clients/12_burger.svg"),
    cohizon: require("../../assets/images/clients/13_cohizon.svg"),
    everstone: require("../../assets/images/clients/14_everstone.svg"),
    fnbasia: require("../../assets/images/clients/15_fnbasia.svg"),
    skf: require("../../assets/images/clients/16_skf.svg"),
    calibre: require("../../assets/images/clients/17_calibre.svg"),
    esgds: require("../../assets/images/clients/18_esgds.svg"),
    bitmore: require("../../assets/images/clients/19_bitmore.svg"),
    lodha: require("../../assets/images/clients/20_lodha.svg"),
    roundImg1: require("../../assets/images/roundImg1.svg"),
    roundImg2: require("../../assets/images/roundImg2.svg"),
    ayana: require("../../assets/images/client_logos/ayana.svg"),
    cgpower: require("../../assets/images/client_logos/cgpower.svg"),
    chemopharm: require("../../assets/images/client_logos/chemopharm.svg"),
    cpc: require("../../assets/images/client_logos/cpc.svg"),
    emrill: require("../../assets/images/client_logos/emrill.svg"),
    sbi_card: require("../../assets/images/client_logos/sbi_card.svg"),
    everenviro: require("../../assets/images/client_logos/everenviro.svg"),
    everise: require("../../assets/images/client_logos/everise.svg"),
    everlife: require("../../assets/images/client_logos/everlife.svg"),
    everlife_1: require("../../assets/images/client_logos/everlife-1.svg"),
    eversource: require("../../assets/images/client_logos/eversource.svg"),
    greencell: require("../../assets/images/client_logos/greencell.svg"),
    iifl_finance: require("../../assets/images/client_logos/iifl_finance.svg"),
    indospace: require("../../assets/images/client_logos/indospace.svg"),
    indostar: require("../../assets/images/client_logos/indostar.svg"),
    infostretch: require("../../assets/images/client_logos/infostretch.svg"),
    kathari: require("../../assets/images/client_logos/kathari.svg"),
    lakshmimachineworks: require("../../assets/images/client_logos/lakshmimachineworks.svg"),
    lithium: require("../../assets/images/client_logos/lithium.svg"),
    lupin: require("../../assets/images/client_logos/lupin.svg"),
    pgp_glass: require("../../assets/images/client_logos/pgp_glass.svg"),
    radiance: require("../../assets/images/client_logos/radiance.svg"),
    ramco: require("../../assets/images/client_logos/ramco.svg"),
    re_sustainability: require("../../assets/images/client_logos/re_sustainability.svg"),
    rhi_magnesita: require("../../assets/images/client_logos/rhi_magnesita.svg"),
    sahyadri: require("../../assets/images/client_logos/sahyadri.svg"),
    servion: require("../../assets/images/client_logos/servion.svg"),
    sjs: require("../../assets/images/client_logos/sjs.svg"),
    softgel: require("../../assets/images/client_logos/softgel.svg"),
    sohanlal: require("../../assets/images/client_logos/sohanlal.svg"),
    sunpharma: require("../../assets/images/client_logos/sunpharma.svg"),
    tatapower: require("../../assets/images/client_logos/tatapower.svg"),
    tolaram: require("../../assets/images/client_logos/tolaram.svg"),
    translumina: require("../../assets/images/client_logos/translumina.svg"),
    zomato: require("../../assets/images/client_logos/zomato.svg"),
    ghcl: require("../../assets/images/client_logos/ghcl.svg"),
    ceat: require("../../assets/images/client_logos/ceat.svg"),
    sulochana: require("../../assets/images/client_logos/sulochana.svg"),
    multiples: require("../../assets/images/client_logos/multiples.svg"),
    republicBank: require("../../assets/images/client_logos/republicBank.svg"),
    refex: require("../../assets/images/client_logos/refex.svg"),
    zee: require("../../assets/images/client_logos/zee.svg"),
    deepak_nitrite_logo: require("../../assets/images/client_logos/deepak_nitrite_logo.svg"),
    everest_industrial_logo: require("../../assets/images/client_logos/everest_industrial_logo.svg"),
    harrys_logo: require("../../assets/images/client_logos/harrys_logo.svg"),
    lloyd_metals: require("../../assets/images/client_logos/lloyd_metals.svg"),
    mastek: require("../../assets/images/client_logos/mastek.svg"),
    protean_logo: require("../../assets/images/client_logos/protean_logo.svg"),
    radiaant_logo: require("../../assets/images/client_logos/radiaant_logo.svg"),
    shapoorji_pallonji_logo: require("../../assets/images/client_logos/shapoorji_pallonji_logo.svg"),
    supremeLogo: require("../../assets/images/client_logos/supremeLogo.svg"),
    suzlonLogo: require("../../assets/images/client_logos/suzlonLogo.svg"),
    amiOrganicsLogo: require("../../assets/images/client_logos/amiOrganicsLogo.svg"),
    epsilonLogo: require("../../assets/images/client_logos/epsilonLogo.svg"),
    sutherlandLogo: require("../../assets/images/client_logos/sutherlandLogo.svg"),
    birlaGroupLogo: require("../../assets/images/client_logos/birlaGroupLogo.svg"),
    cumiLogo: require("../../assets/images/client_logos/cumiLogo.svg"),
    narayanaHealthLogo: require("../../assets/images/client_logos/narayanaHealthLogo.svg"),
    jKCements: require("../../assets/images/client_logos/jKCements.svg"),
    kuantumPapers: require("../../assets/images/client_logos/kuantumPapers.svg"),
    ashokLeyland: require("../../assets/images/client_logos/ashokLeyland.svg"),
    gokaldasExports: require("../../assets/images/client_logos/gokaldasExports.svg"),
    grInfra: require("../../assets/images/client_logos/grInfra.svg"),
    igarashiMotors: require("../../assets/images/client_logos/igarashiMotors.svg"),
    bluePlanet: require("../../assets/images/client_logos/bluePlanet.svg"),
    escortsKubota: require("../../assets/images/client_logos/escortsKubota.svg"),
    indoramaCorporation: require("../../assets/images/client_logos/indoramaCorporation.svg"),
    lTFinance: require("../../assets/images/client_logos/lTFinance.svg"),
    infosys: require("../../assets/images/client_logos/infosys.svg"),
    polyplastics: require("../../assets/images/client_logos/polyplastics.svg"),
    prismJohnson: require("../../assets/images/client_logos/prism-johnson.svg"),
    tataConsumerProducts: require("../../assets/images/client_logos/tata-consumer-products.svg"),
    trident: require("../../assets/images/client_logos/trident.svg"),
    belindaSabatierMondjo: require("../../assets/images/BelindaSabatierMondjo.svg"),
  },

  icons: {
    email: require("../../assets/icons/email.svg"),
    phone: require("../../assets/icons/phone.svg"),
    dataManagementCover: require("../../assets/Header/dataManagementCover.svg"),
    uReportingIcon: require("../../assets/Header/logo-reporting.svg"),
    uDataManagementIcon: require("../../assets/Header/logo-data-management.svg"),
    uAnalyticsIcon: require("../../assets/Header/logo-analytics.svg"),
    uGrantIcon: require("../../assets/Header/logo-grant-management.svg"),
    nzIcon: require("../../assets/Header/nz-logo.svg"),
    ermIcon: require("../../assets/Header/erm-logo.svg"),
    scmIcon: require("../../assets/Header/scm-logo.svg"),
    paIcon: require("../../assets/Header/pa-logo.svg"),
    newsletterIcon: require("../../assets/Header/newsletter.svg"),
    mediaIcon: require("../../assets/Header/media_icon.svg"),
    newsroomIcon: require("../../assets/Header/newsroom_icon.svg"),
    blogsIcon: require("../../assets/Header/blogs.svg"),
    client_nav_icon: require("../../assets/icons/client_nav_icon.svg"),
    fbIcon: require("../../assets/FooterImages/facebook.svg"),
    hoverFbIcon: require("../../assets/FooterImages/hover-facebook.svg"),
    igIcon: require("../../assets/FooterImages/instagram.svg"),
    hoverIgIcon: require("../../assets/FooterImages/hover-instagram.svg"),
    linkedinIcon: require("../../assets/FooterImages/linkedin.svg"),
    hoverLinkedinIcon: require("../../assets/FooterImages/hover-linkedin.svg"),
    hoverTwitterIcon: require("../../assets/FooterImages/hover-twitter.svg"),
    twitterIcon: require("../../assets/FooterImages/twitter.svg"),
    gdprIcon: require("../../assets/FooterImages/gdpr-icon.svg"),
    isoIcon: require("../../assets/FooterImages/iso-icon.svg"),
    dunsNumber: require("../../assets/FooterImages/duns-number.svg"),
    linkedLogo: require("../../assets/icons/rahul.svg"),
    rightArrowIcon: require("../../assets/icons/right-arrow.svg"),
    rightArrowIconWhite: require("../../assets/icons/rightArrowIconWhite.svg"),
    arrowUp: require("../../assets/icons/arrow-angle-up.svg"),
    standard: require("../../assets/pricing/standard.svg"),
    premium: require("../../assets/pricing/premium.svg"),
    bespoke: require("../../assets/pricing/bespoke.svg"),
    reseller: require("../../assets/icons/reseller.svg"),
    affliate: require("../../assets/icons/affliate.svg"),
    consulting: require("../../assets/icons/consulting.svg"),
    technology: require("../../assets/icons/technology.svg"),
    companyAboutus: require("../../assets/icons/about_us_w_circle.svg"),
    companyPartner: require("../../assets/icons/partners_w_circle.svg"),
    views: require("../../assets/Newsletter/view.svg"),
    play: require("../../assets/icons/play.svg"),
    pause: require("../../assets/icons/pause.svg"),
    forward: require("../../assets/icons/forward.svg"),
    backward: require("../../assets/icons/backward.svg"),
    volume: require("../../assets/icons/volume.svg"),
    muteVolume: require("../../assets/icons/mute-volume.svg"),
    download: require("../../assets/icons/download-icon.svg"),
    podcastNav: require("../../assets/icons/podcast-nav-icon.svg"),
    closeIcon: require("../../assets/icons/closeIcon.svg"),
    searchIcon: require("../../assets/icons/searchIcon.svg"),
    location: require("../../assets/icons/location.svg"),
    careersNavIcon: require("../../assets/icons/careersNavIcon.svg"),
    accordionArrow: require("../../assets/icons/accordion arrow.svg"),
    caseStudyIcon: require("../../assets/icons/caseStudyIcon.svg"),
    maIcon: require("../../assets/icons/maIcon.svg"),
  },
};

export default assets;
