/** @format */

import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";

function NewsletterTwentySix() {
  return (
    <>
      <section className="about-banner-section" style={{ padding: "150px 0 62px" }}>
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}>
              Newsletter November 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-six-container">
        <MetaTags>
          <title>
            Germany Allocates $3 Billion to Aid Industry Shift Towards Carbon Neutrality.
          </title>
          <meta
            name="description"
            content="Germany has allocated $3.1 billion to help decarbonize key industrial sectors such as chemicals, glass, and paper as part of its goal to become climate-neutral by 2045."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
        </MetaTags>
        <div className="Newsletter-title1" style={{ paddingTop: "56px", fontSize: "32px" }}>
          Germany Allocates $3 Billion to Aid Industry Shift Towards Carbon Neutrality.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2024-1.jpeg").default}
            alt="nov2024-1"
          />
        </figure>
        <p>
          Germany has allocated $3.1 billion to help decarbonize key industrial sectors such as
          chemicals, glass, and paper as part of its goal to become climate-neutral by 2045. This
          funding will support 15 industrial companies in reducing their carbon emissions through
          long-term climate protection contracts lasting 15 years. The initiative aims to cut 17
          million metric tons of emissions, which contributes to Germany’s broader efforts to lower
          emissions from 674 million tons in 2023. Companies such as BASF and Suedzucker are among
          the primary beneficiaries.
        </p>
        <p>
          The funding addresses the high costs of shifting to greener production methods by
          compensating industries for the additional expenses associated with adopting
          climate-friendly technologies. However, there is some criticism over the program's
          cost-efficiency, as the overall impact on Germany’s total emissions may be limited. The
          government views this as a necessary step until renewable energy becomes more accessible
          and affordable.
        </p>
        <p>
          This initiative is part of Germany's broader commitment to sustainable industrial
          transformation. In 2025, a second round of auctions will allocate additional subsidies,
          continuing the country's efforts to decarbonize its economy in alignment with European
          climate goals. These measures follow adjustments to Germany’s climate financing
          strategies, which were influenced by a constitutional ruling on the use of debt for such
          projects.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Germany's $3 billion initiative to decarbonize industries signals a crucial shift
            towards sustainable production. While it provides financial support to ease the
            transition, it demands long-term operational changes. The move encourages innovation in
            cleaner technologies, positioning industries for a more resilient future amid evolving
            global environmental standards and economic pressures.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada to Mandate Climate Risk Reporting for Large Companies as Part of Net-Zero
          Transition.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/nov2024-2.jpeg").default}
              alt="nov2024-2"
            />
          </figure>
        </div>
        <p>
          Canada is set to introduce mandatory climate disclosure regulations for large, federally
          incorporated companies as part of its broader effort to address climate change and
          transition to a net-zero economy by 2050. These disclosures will require companies to
          report on climate-related financial risks and opportunities, using frameworks such as the
          Task Force on Climate-related Financial Disclosures (TCFD). This initiative follows
          similar requirements already implemented for financial institutions and aims to improve
          corporate transparency, foster investor confidence, and drive sustainable economic growth.
          The government also plans to launch "Made-in-Canada" sustainable investment guidelines to
          further guide private sector investments towards green projects.
        </p>
        <p>
          This new regulation, while primarily targeting larger companies, will not initially apply
          to small and medium-sized enterprises (SMEs). However, the government is exploring ways to
          encourage voluntary disclosures from these businesses. The goal is to harmonize these
          regulations across different sectors and align them with global sustainability standards
          to attract more investment in Canada's growing green economy. The upcoming climate
          disclosure rules will be crucial for increasing corporate accountability and mitigating
          the risks posed by climate change, while simultaneously stimulating economic
          opportunities, particularly in sectors like clean energy, manufacturing, and technology.
        </p>
        <p>
          In addition to regulatory changes, Canada is investing heavily in clean technologies,
          carbon capture, and renewable energy infrastructure to meet its climate goals. Over $160
          billion in funding, including tax credits, is being allocated to these areas, reinforcing
          the nation’s commitment to achieving a sustainable and resilient economy. These efforts
          are expected to create jobs, reduce carbon emissions, and secure Canada's position as a
          leader in the global transition to a low-carbon future.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Businesses can prepare for Canada’s mandatory climate disclosures by integrating climate
            risk assessments into financial planning, enhancing data collection systems for
            emissions tracking, and establishing cross-functional teams for compliance. Leveraging
            frameworks like TCFD and investing in ESG software will help ensure readiness and align
            with the upcoming regulatory requirements.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          HKMA Introduces Strategic Action Agenda with Eight Key Goals for Sustainable Finance.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2024-3.jpg").default}
            alt="nov2024-3"
          />
        </figure>
        <p>
          The Hong Kong Monetary Authority (HKMA) recently unveiled its Sustainable Finance Action
          Agenda, aiming to cement Hong Kong's position as a key player in sustainable finance
          within the region. This initiative is built around eight key goals designed to promote
          sustainable practices, reduce carbon footprints, and enhance transparency in the financial
          sector. Among the central goals is the commitment for all banks operating in Hong Kong to
          achieve net zero emissions in their own operations by 2030, and in their financed
          emissions by 2050. The HKMA has set a tentative deadline for banks to present their
          Paris-aligned transition plans by 2030, using a ‘comply or explain’ framework to ensure
          accountability and transparency. These efforts will be supported by a new Supervisory
          Policy Manual, which will help guide banks in creating robust, actionable, and up-to-date
          plans to meet climate targets
        </p>
        <p>
          In addition to these net zero targets, the HKMA has emphasized the need for increased
          transparency regarding climate-related risks and opportunities. To that end, the authority
          is working on enhancing disclosure requirements for banks, particularly in line with
          international standards such as the IFRS Sustainability Disclosure Standards. The
          authority plans to consult the sector on the Pillar 3 disclosure framework concerning
          climate-related financial risks. This move aligns with growing global demands for clearer,
          more comprehensive reporting on environmental and sustainability metrics, ensuring that
          Hong Kong remains competitive and compliant with international norms.
        </p>
        <p>
          Another critical aspect of the Action Agenda is addressing the talent and knowledge gap in
          sustainable finance within the region. As the landscape of sustainable finance continues
          to evolve, HKMA recognizes the need to equip banking professionals with the necessary
          skills and expertise. To this end, the HKMA will collaborate with the industry to assess
          training needs and launch a professional module on Green and Sustainable Finance under the
          Enhanced Competency Framework.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The HKMA's Sustainable Finance Action Agenda will significantly impact the banking
            industry by requiring banks to prioritize net-zero emissions, enhance climate-related
            disclosures, and integrate climate risks into their risk management frameworks. It will
            drive innovation in green financial products and demand upskilling of banking
            professionals in sustainable finance.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Allocates €4.8 Billion to Accelerate Green Technology and Decarbonization Efforts.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2024-4.jpeg").default}
            alt="nov2024-4"
          />
        </figure>
        <p>
          The European Union has announced a substantial investment of €4.8 billion in
          decarbonization initiatives across 18 countries, funded through its Emissions Trading
          System (EU ETS). This funding will support 85 projects focused on renewable energy,
          hydrogen production, and carbon capture, among other innovations aimed at reducing
          greenhouse gas emissions. The projects are part of the EU Innovation Fund, which is
          crucial to the region's efforts to meet its ambitious climate goals by 2050. Major sectors
          involved include energy, transport, and industrial manufacturing, with a strong focus on
          scaling up clean technologies and developing sustainable infrastructure.
        </p>
        <p>
          This investment is a key component of the EU’s broader climate strategy, particularly as
          it looks to enhance the role of the EU ETS, which has been a cornerstone of its carbon
          reduction efforts. The system, established in 2005, now covers industries such as steel,
          cement, and aviation, and is expected to generate significant revenue over the next
          decade. These funds are being channeled into decarbonization efforts and the development
          of low-carbon industries, helping Europe to stay competitive in the global clean energy
          market while reducing reliance on fossil fuels.
        </p>
        <p>
          The allocation of funds includes €2.4 billion for large-scale decarbonization projects,
          €1.4 billion for cleantech manufacturing, and €200 million for pilot projects focused on
          deep decarbonization. The Innovation Fund aims to cover up to 60% of project costs,
          ensuring that companies can advance cutting-edge technologies that are critical to
          reducing emissions and boosting the EU's competitiveness in a carbon-neutral future. These
          investments are expected to drive significant reductions in greenhouse gases while
          supporting the growth of sustainable industries across the continent.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            This EU initiative provides companies with financial backing to accelerate their
            transition to low-carbon technologies, reducing operational risks associated with future
            carbon costs. By securing funding for innovative projects, businesses can lead in the
            competitive clean-tech space, enhance their market positioning, and capitalize on
            growing demand for sustainable products. Moreover, it aligns with evolving regulatory
            frameworks, helping firms mitigate potential compliance costs while advancing long-term
            sustainability goals.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          SBTi Launches New Platform to Streamline Climate Target Validation for Businesses.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2024-5.jpeg").default}
            alt="nov2024-5"
          />
        </figure>

        <p>
          The Science Based Targets initiative (SBTi) has launched a new platform to make the
          validation process for science-based targets more efficient, addressing a sharp increase
          in demand from businesses and financial institutions. The platform, sbtiservices.com,
          streamlines the process of setting and validating climate targets, providing tools like a
          Validation Portal with tiered pricing to support companies of various sizes. This effort
          is part of SBTi’s broader mission to enable organizations to align their operations with
          global climate action, supporting the ambitious goals outlined in international climate
          agreements.
        </p>
        <p>
          The expansion of SBTi’s services is timely, with more than 4,200 companies already having
          their targets validated through the initiative, marking a significant increase compared to
          previous periods. Notably, around 39% of the global market capital is now represented by
          businesses with validated or committed science-based targets. This surge reflects a
          heightened awareness and commitment within the corporate sector to contribute to
          decarbonization, with the new platform offering enhanced resources to meet the growing
          demand from organizations aiming to set credible and impactful climate goals.
        </p>
        <p>
          SBTi has also noted substantial growth across specific sectors, such as energy,
          transportation, and finance, with 83% of financial institutions having adopted
          science-based targets. This trend signifies an increasing shift toward
          sustainability-driven business practices. By simplifying the target validation process and
          making it more accessible, SBTi is playing a pivotal role in supporting organizations’
          efforts to meet their sustainability commitments, ultimately contributing to broader
          global efforts to limit climate change
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new SBTi platform offers businesses a streamlined, accessible process for validating
            climate targets, ensuring easier alignment with global sustainability goals. It
            simplifies compliance, reduces administrative burden, and provides clear guidance,
            helping companies improve transparency and meet investor and regulatory expectations,
            enhancing their reputation and long-term sustainability strategy.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentySix;
