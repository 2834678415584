/** @format */

import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";

function NewsletterTwentySeven() {
  return (
    <>
      <section className="about-banner-section" style={{ padding: "150px 0 62px" }}>
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}>
              Newsletter December 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-seven-container">
        <MetaTags>
          <title>
            IPSASB Sets New Global Benchmark for Public Sector Climate Reporting Standards.
          </title>
          <meta
            name="description"
            content="The International Public Sector Accounting Standards Board (IPSASB) has released its inaugural exposure draft for Sustainability Reporting Standards, focusing on climate-related disclosures."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
        </MetaTags>
        <div className="Newsletter-title1" style={{ paddingTop: "56px", fontSize: "32px" }}>
          IPSASB Sets New Global Benchmark for Public Sector Climate Reporting Standards.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2024-1.jpeg").default}
            alt="benchmark"
          />
        </figure>
        <p>
          The International Public Sector Accounting Standards Board (IPSASB) has released its
          inaugural exposure draft for Sustainability Reporting Standards, focusing on
          climate-related disclosures. These guidelines aim to enhance transparency and
          accountability in public sector entities, bridging a significant gap in global reporting
          frameworks. The standards address two critical aspects: climate-related risks and
          opportunities affecting the entity's operations, and the outcomes of public policy
          programs designed to combat climate challenges.
        </p>
        <p>
          This draft integrates global best practices such as the Taskforce for Climate-Related
          Financial Disclosures (TCFD) while tailoring requirements for the public sector's unique
          roles. By emphasizing governance, strategy, risk management, and metrics, the IPSASB
          framework supports comprehensive and comparable climate reporting. Additionally, it
          proposes separate reporting requirements for entities directly influencing climate
          policies, fostering greater accountability in implementing climate-related public
          initiatives.
        </p>
        <p>
          IPSASB SRS ED 1 proposes that an entity disclose performance indicators and targets it
          uses to measure the performance towards its strategic goals, which includes Scopes 1, 2,
          and 3 greenhouse gas emissions. IPSASB SRS ED 1 allows for use of methodologies other than
          the GHG Protocol: A Corporate Accounting and Reporting Standard where an entity determines
          another methodology more appropriate.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Public sector entities should embed sustainability into strategic planning by fostering
            interdisciplinary collaboration, leveraging data analytics for innovative reporting, and
            aligning governance structures to climate goals. Embracing adaptive methodologies beyond
            standard protocols and prioritizing transparent stakeholder engagement will ensure
            readiness for IPSASB SRS ED 1 while driving impactful, systemic climate action.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UK Advances Regulation on ESG Ratings Providers to Enhance Transparency and
          Accountability.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/dec2024-2.jpeg").default}
              alt="Esg ratings"
            />
          </figure>
        </div>
        <p>
          The UK government has initiated a comprehensive consultation process to regulate ESG
          ratings providers, a critical step in aligning financial markets with its ambitious
          net-zero targets. ESG ratings influence trillions of dollars in global investments, with
          projections indicating $33.9 trillion in assets under management will incorporate ESG
          considerations within the next three years. However, concerns over opaque methodologies,
          inconsistent governance, and potential conflicts of interest have prompted action to
          ensure these tools promote trust and transparency in financial markets.
        </p>
        <p>
          The proposed framework aims to address systemic challenges in the ESG ratings sector,
          which currently lacks consistent oversight despite its growing impact. For example, an ESG
          rating change can significantly influence asset allocation and market behavior. Studies
          show that 65% of institutional investors rely on ESG ratings weekly, yet the methods and
          criteria behind these ratings remain largely unregulated. The UK government plans to
          implement internationally aligned standards, leveraging recommendations from organizations
          like the International Organization of Securities Commissions (IOSCO), to enhance the
          credibility of ESG metrics and improve investor confidence.
        </p>
        <p>
          To balance oversight with innovation, the proposals consider proportional regulatory
          measures for smaller providers, who represent over 40% of the market but may face higher
          compliance burdens. The consultation also explores extending regulation to overseas
          providers supplying ratings to UK users, ensuring a level playing field. As one of the
          first jurisdictions to pursue such regulation, the UK seeks to cement its position as a
          global leader in sustainable finance, encouraging stakeholders to contribute insights that
          will shape the future of ESG integration.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The proposed regulation addresses critical challenges in the ESG ratings sector,
            including inconsistent methodologies, lack of transparency, and potential conflicts of
            interest. By standardizing practices and aligning with international frameworks, it aims
            to enhance data reliability, reduce greenwashing risks, and build investor confidence.
            The initiative also mitigates market fragmentation, fostering seamless ESG integration
            into global capital allocation strategies.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Brazil Reduces Amazon Deforestation by 31%, Advancing Global Climate and Sustainability
          Goals.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2024-3.jpeg").default}
            alt="deforestation-in-Amazon"
          />
        </figure>
        <p>
          Brazil has achieved a significant environmental milestone by reducing deforestation in the
          Amazon by 30.6% over the past year, bringing the rate to its lowest level since 2015.
          Between August 2023 and July 2024, approximately 6,288 square kilometers of rainforest
          were cleared, a substantial decrease from previous years. This progress is largely
          attributed to enhanced environmental enforcement and conservation policies implemented by
          the current administration.
        </p>
        <p>
          In alignment with its commitment to combating climate change, Brazil has announced an
          ambitious target to reduce greenhouse gas emissions by 59% to 67% by 2035, compared to
          2005 levels. This new goal, part of Brazil's updated Nationally Determined Contribution
          under the Paris Agreement, translates to a reduction of approximately 850 million to 1.05
          billion tons of carbon dioxide equivalent by 2035. The plan encompasses sectoral
          strategies focused on both mitigation and adaptation to climate impacts.
        </p>
        <p>
          Despite these advancements, challenges persist. Some agribusiness-friendly states are
          attempting to weaken rainforest protections, responding to pressures from cattle ranchers
          and soybean growers seeking to expand agricultural activities. These efforts could
          undermine national achievements in reducing deforestation and threaten Brazil's commitment
          to halt deforestation by 2030. Additionally, severe drought conditions have led to a surge
          in wildfires, further impacting the Amazon's ecosystem.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Brazil’s achievement in reducing Amazon deforestation by 31% is driven by enhanced
            enforcement, targeted policies, and global collaboration. This accomplishment is vital
            for global sustainability, protecting a critical carbon sink, conserving biodiversity,
            and combating climate change, while setting an example for other nations to pursue
            effective environmental governance and action.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UAE Allocates $54 Billion to Drive Renewable Energy Transition by 2030.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2024-4.jpeg").default}
            alt="uae"
          />
        </figure>
        <p>
          The United Arab Emirates (UAE) has announced plans to invest $54.4 billion in renewable
          energy development by 2030, aiming to triple its clean energy production. This move aligns
          with the nation’s updated Energy Strategy 2050, which seeks to address growing domestic
          energy demands while ensuring a lower carbon footprint. The strategy outlines a framework
          to reduce emissions, enhance energy efficiency, and diversify energy sources, focusing on
          renewables and other clean technologies.
        </p>
        <p>
          Key projects include the expansion of the Mohammed bin Rashid Al Maktoum Solar Park,
          projected to generate up to 5 gigawatts of solar power by the end of the decade. The UAE
          also prioritises producing low-emission hydrogen and developing supportive infrastructure
          for electric vehicles to accelerate its transition to a sustainable energy mix. These
          initiatives are part of a broader effort to position the UAE as a regional leader in
          renewable energy innovation and deployment.
        </p>
        <p>
          To achieve net-zero emissions by 2050, the UAE's updated strategy targets increasing the
          share of renewable energy to 30% by 2031. This investment demonstrates a commitment to
          balancing economic development with environmental sustainability, reflecting the nation’s
          proactive approach to global climate goals.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The UAE’s $54 billion investment in renewable energy offers significant benefits to
            companies by fostering innovation, reducing energy costs, and creating new market
            opportunities in clean technologies. Businesses can capitalize on incentives for
            adopting sustainable practices, enhancing competitiveness, and aligning with global ESG
            expectations, attracting investors and ensuring long-term resilience.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          COP29 agreements set path to boost global economy through climate finance and carbon
          markets.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2024-5.jpeg").default}
            alt="cop29"
          />
        </figure>

        <p>
          The 29th United Nations Climate Change Conference (COP29) in Baku, Azerbaijan, concluded
          with a landmark agreement to triple climate finance for developing nations, aiming to
          mobilize $300 billion annually by 2035. This New Collective Quantified Goal on Climate
          Finance (NCQG) represents a substantial increase from previous commitments, underscoring a
          global dedication to supporting vulnerable countries in mitigating and adapting to climate
          change.
        </p>
        <p>
          In addition to the financial commitments, COP29 achieved significant progress in
          operationalizing international carbon markets. Delegates finalized guidelines under
          Article 6 of the Paris Agreement, establishing a framework for high-integrity carbon
          credit trading between countries. This development is expected to enhance transparency and
          efficiency in carbon markets, facilitating greater global cooperation in reducing
          greenhouse gas emissions.
        </p>
        <p>
          These outcomes from COP29 mark a pivotal advancement in global climate action, reflecting
          a unified effort to address the pressing challenges of climate change through increased
          financial support and collaborative market mechanisms. The agreements lay a solid
          foundation for future initiatives, fostering optimism for continued progress in the
          transition to a sustainable and resilient global economy.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The COP29 agreements have the potential to transform the global economy by accelerating
            the transition to sustainable, low-carbon industries. Tripling climate finance and
            operationalizing carbon markets will stimulate green investments, create new economic
            sectors, and drive innovation. These initiatives can enhance global economic stability
            while fostering equitable growth across regions.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentySeven;
