import React from "react";
import Banner from "./Banner";
import Security from "./Security";
import Solution from "./Solution";
import Resources from "./Resources";
import Licenses from "./Licenses";
import MetaTags from "react-meta-tags";
import Investor from "./Investor";
import LogoCarousel from "./LogoCarousel";
import Clients from "./Clients";

function Landing(props) {
  return (
    <>
      <MetaTags>
        <title>
          ESG Software - Sustainability Data Management & Data Reporting
          Software
        </title>
        <meta
          name="description"
          content="Updapt is ESG and Sustainability data management software which includes ESG Reporting, Netzero, Analysis, ESG Risk management with supplier-assessment."
        />
        <meta
          property="og:title"
          content="ESG Software - Sustainability Data Management & Data Reporting Software"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Banner />
      <div className="slider">
        <LogoCarousel />
      </div>
      <Solution />
      <div className="border-line"></div>
      <Security securityDetails={props.securityDetails} />
      <div className="border-line"></div>
      {/* <Pricing pricingPlans={props.pricingPlans} /> */}
      <Investor />
      <Licenses licencingDetails={props.licencingDetails} />
      <Clients
        clientDetails={props.clientDetails}
        handleBackButton={props.handleBackButton}
        index={props.index}
        handleNextButton={props.handleNextButton}
        characterCount={props.characterCount}
        handleReadMore={props.handleReadMore}
        readMoreBool={props.readMoreBool}
      />
      <Resources blogsData={props.blogsData} />
    </>
  );
}

export default Landing;
