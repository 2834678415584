import React from "react";
import assets from "../../../shared/constants/assetVariables";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LogoCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 2400,
    autoplay: true,
    autoplaySpeed: 0,
    mobileFirst: true,
    arrows: false,
    pauseOnHover: true,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div className="slide-track">
      <div className="slide">
        <h2 className="slider-header-txt">Trusted by clients worldwide</h2>
        <div className="logo-scroll">
          <Slider {...settings}>
            <div className="img-width">
              <img src={assets.images.tatapower} alt="tatapower" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.tolaram} alt="tolaram" />
            </div>
            <div className="img-width">
              <img src={assets.images.emrill} alt="emrill" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.sbi_card} alt="sbi_card" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.iifl_finance} alt="iifl_finance" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.shapoorji_pallonji_logo} alt="shapoorji_pallonji_logo" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.skf} alt="skf" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.rhi_magnesita} alt="rhi_magnesita" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.burger} alt="burger" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.zomato} alt="zomato" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.cipla} alt="cipla" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.sunpharma} alt="sunpharma" />
            </div>
            <div className="img-width">
              <img src={assets.images.hcl} alt="hcl" />
            </div>
            <div className="img-width">
              <img src={assets.images.apollo} alt="apollo" />
            </div>
            <div className="img-width">
              <img src={assets.images.ayana} alt="ayana" />
            </div>
            <div className="img-width">
              <img src={assets.images.bitmore} alt="bitmore" />
            </div>
            <div className="img-width">
              <img src={assets.images.calibre} alt="calibre" />
            </div>
            <div className="img-width">
              <img src={assets.images.cgpower} alt="cgpower" />
            </div>
            <div className="img-width">
              <img src={assets.images.deepak_nitrite_logo} alt="deepak_nitrite_logo" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.everest_industrial_logo} alt="everest_industrial_logo" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.harrys_logo} alt="harrys_logo" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.lloyd_metals} alt="lloyd_metals" />
            </div>
            <div className="img-width">
              <img src={assets.images.supremeLogo} alt="supremeLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.suzlonLogo} alt="suzlonLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.amiOrganicsLogo} alt="amiOrganicsLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.epsilonLogo} alt="epsilonLogo" />
            </div>
            {/* <div className="img-width">
              <img src={assets.images.chemopharm} alt="chemopharm" />
            </div> */}
            <div className="img-width">
              <img src={assets.images.cochin} alt="cochin" />
            </div>
            <div className="img-width">
              <img src={assets.images.cookieman} alt="cookieman" />
            </div>
            {/* <div className="img-width">
              <img src={assets.images.cpc} alt="cpc" />
            </div> */}
            <div className="img-width">
              <img src={assets.images.dsg} alt="dsg" />
            </div>
            {/* <div className="img-width">
              <img src={assets.images.everenviro} alt="everenviro" />
            </div> */}
            {/* <div className="img-width">
              <img src={assets.images.everise} alt="everise" />
            </div> */}
            {/* <div className="img-width">
              <img src={assets.images.everlife} alt="everlife" />
            </div>
            <div className="img-width">
              <img src={assets.images.everlife_1} alt="everlife_1" />
            </div> */}
            {/* <div className="img-width">
              <img src={assets.images.eversource} alt="eversource" />
            </div> */}
            <div className="img-width">
              <img src={assets.images.everstone} alt="everstone" />
            </div>
            <div className="img-width">
              <img src={assets.images.fnbasia} alt="fnbasia" />
            </div>
            <div className="img-width">
              <img src={assets.images.greencell} alt="greencell" />
            </div>
            <div className="img-width">
              <img src={assets.images.multiples} alt="multiples" />
            </div>
            <div className="img-width">
              <img src={assets.images.republicBank} alt="republicBank" />
            </div>
            <div className="img-width">
              <img src={assets.images.refex} alt="refex" />
            </div>
            <div className="img-width">
              <img src={assets.images.zee} alt="zee" />
            </div>
            <div className="img-width">
              <img src={assets.images.infosys} alt="infosys" />
            </div>
            <div className="img-width">
              <img src={assets.images.polyplastics} alt="polyplastics" />
            </div>
            <div className="img-width">
              <img src={assets.images.prismJohnson} alt="prismJohnson" />
            </div>
          </Slider>
        </div>
        <div className="logo-scroll">
          <Slider {...settings}>
            <div className="img-width">
              <img src={assets.images.indospace} alt="indospace" />
            </div>
            <div className="img-width">
              <img src={assets.images.indostar} alt="indostar" />
            </div>
            <div className="img-width">
              <img src={assets.images.infostretch} alt="infostretch" />
            </div>
            <div className="img-width">
              <img src={assets.images.kathari} alt="kathari" />
            </div>
            <div className="img-width">
              <img src={assets.images.lakshmimachineworks} alt="lakshmimachineworks" />
            </div>
            <div className="img-width">
              <img src={assets.images.mastek} alt="mastek" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.sutherlandLogo} alt="sutherlandLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.birlaGroupLogo} alt="birlaGroupLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.cumiLogo} alt="cumiLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.narayanaHealthLogo} alt="narayanaHealthLogo" />
            </div>
            <div className="img-width">
              <img src={assets.images.protean_logo} alt="protean_logo" />
            </div>{" "}
            <div className="img-width">
              <img src={assets.images.radiaant_logo} alt="radiaant_logo" />
            </div>
            <div className="img-width">
              <img src={assets.images.licious} alt="licious" />
            </div>
            <div className="img-width">
              <img src={assets.images.lithium} alt="lithium" />
            </div>
            <div className="img-width">
              <img src={assets.images.lodha} alt="lodha" />
            </div>
            <div className="img-width">
              <img src={assets.images.lupin} alt="lupin" />
            </div>
            <div className="img-width">
              <img src={assets.images.omega} alt="omega" />
            </div>
            <div className="img-width">
              <img src={assets.images.pgp_glass} alt="pgp_glass" />
            </div>
            <div className="img-width">
              <img src={assets.images.pricol} alt="pricol" />
            </div>
            <div className="img-width">
              <img src={assets.images.radiance} alt="radiance" />
            </div>
            <div className="img-width">
              <img src={assets.images.ramco} alt="ramco" />
            </div>
            <div className="img-width">
              <img src={assets.images.re_sustainability} alt="re_sustainability" />
            </div>
            <div className="img-width">
              <img src={assets.images.sahyadri} alt="sahyadri" />
            </div>
            <div className="img-width">
              <img src={assets.images.cohizon} alt="cohizon" />
            </div>
            <div className="img-width">
              <img src={assets.images.servion} alt="servion" />
            </div>
            <div className="img-width">
              <img src={assets.images.sjs} alt="sjs" />
            </div>
            <div className="img-width">
              <img src={assets.images.softgel} alt="softgel" />
            </div>
            <div className="img-width">
              <img src={assets.images.sohanlal} alt="sohanlal" />
            </div>
            <div className="img-width">
              <img src={assets.images.sulochana} alt="sulochana" />
            </div>
            <div className="img-width">
              <img src={assets.images.translumina} alt="translumina" />
            </div>
            <div className="img-width">
              <img src={assets.images.ghcl} alt="ghcl" />
            </div>
            <div className="img-width">
              <img src={assets.images.ceat} alt="ceat" />
            </div>
            <div className="img-width">
              <img src={assets.images.jKCements} alt="jKCements" />
            </div>
            <div className="img-width">
              <img src={assets.images.kuantumPapers} alt="kuantumPapers" />
            </div>
            <div className="img-width">
              <img src={assets.images.ashokLeyland} alt="ashokLeyland" />
            </div>
            <div className="img-width">
              <img src={assets.images.gokaldasExports} alt="gokaldasExports" />
            </div>
            <div className="img-width">
              <img src={assets.images.grInfra} alt="grInfra" />
            </div>
            <div className="img-width">
              <img src={assets.images.igarashiMotors} alt="igarashiMotors" />
            </div>
            <div className="img-width">
              <img src={assets.images.bluePlanet} alt="bluePlanet" />
            </div>
            <div className="img-width">
              <img src={assets.images.escortsKubota} alt="escortsKubota" />
            </div>
            <div className="img-width">
              <img src={assets.images.indoramaCorporation} alt="indoramaCorporation" />
            </div>
            <div className="img-width">
              <img src={assets.images.lTFinance} alt="lTFinance" />
            </div>
            <div className="img-width">
              <img src={assets.images.tataConsumerProducts} alt="tataConsumerProducts" />
            </div>
            <div className="img-width">
              <img src={assets.images.trident} alt="trident" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
export default LogoCarousel;
