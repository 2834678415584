import React from "react";

import MetaTags from "react-meta-tags";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";

function Reporting() {
  return (
    <>
      <MetaTags>
        <title>ESG Data Reporting | ESG Reporting Platform | ESG Reporting Software</title>
        <meta
          name="description"
          content="Updapt ESG Reporting Software simplifies your reporting process, and it encompasses various frameworks: GRI, CSRD, ESRS, TCFD, CDP, and BRSR."
        />
        <meta
          property="og:title"
          content=" ESG Data Reporting | ESG Reporting Platform | ESG Reporting Software"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
      </MetaTags>
      <Intro></Intro>

      <FeatureOne />

      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
    </>
  );
}

export default Reporting;
