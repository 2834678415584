/** @format */

import React from "react";
import "../SCSS/styles.scss";
import Intro from "./Intro";
import Subscribe from "./Subscribe";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";
import NewsletterTwentySeven from "../NewsletterTwentySeven";
import NewsletterTwentySix from "../NewsletterTwentySix";
import NewsletterTwentyFive from "../NewsletterTwentyFive";
import NewsletterTwentyFour from "../NewsletterTwentyFour";
import NewsletterTwentyThree from "../NewsletterTwentyThree";
import NewsletterTwentyTwo from "../NewsletterTwentyTwo";
import NewsletterTwentyOne from "../NewsletterTwentyOne";
import NewsletterTwenty from "../NewsletterTwenty";
import NewsletterNineteen from "../NewsletterNineteen";
import NewsletterEighteen from "../NewsletterEighteen";
import NewsletterSeventeen from "../NewsletterSeventeen";
import NewsletterSixteen from "../NewsletterSixteen";
import NewsletterFifteen from "../NewsletterFifteen";
import NewsletterFourteen from "../NewsletterFourteen";
import NewsletterThirteen from "../NewsletterThirteen";
import NewsletterEleven from "../NewsletterEleven";
import NewsletterTwelve from "../NewsletterTwelve";
import NewsletterTen from "../NewsletterTen";
import NewsletterNine from "../NewsletterNine";
import NewsletterEight from "../NewsletterEight";
import NewsletterSeven from "../NewsletterSeven";
import NewsletterSix from "../NewsletterSix";
import NewsletterFive from "../NewsletterFive";
import NewsletterFour from "../NewsletterFour";
import NewsletterThree from "../NewsletterThree";
import NewsletterTwo from "../NewsletterTwo";
import NewsletterOne from "../NewsletterOne";

function Newsletter({ newslettersData, handleClick, month, year }) {
  const newsletterHash = {
    "december 2024": <NewsletterTwentySeven />,
    "november 2024": <NewsletterTwentySix />,
    "october 2024": <NewsletterTwentyFive />,
    "september 2024": <NewsletterTwentyFour />,
    "august 2024": <NewsletterTwentyThree />,
    "july 2024": <NewsletterTwentyTwo />,
    "june 2024": <NewsletterTwentyOne />,
    "may 2024": <NewsletterTwenty />,
    "april 2024": <NewsletterNineteen />,
    "march 2024": <NewsletterEighteen />,
    "february 2024": <NewsletterSeventeen />,
    "january 2024": <NewsletterSixteen />,

    "december 2023": <NewsletterFifteen />,
    "november 2023": <NewsletterFourteen />,
    "october 2023": <NewsletterThirteen />,
    "september 2023": <NewsletterTwelve />,
    "august 2023": <NewsletterEleven />,
    "july 2023": <NewsletterTen />,
    "june 2023": <NewsletterNine />,
    "may 2023": <NewsletterEight />,
    "april 2023": <NewsletterSeven />,
    "march 2023": <NewsletterSix />,
    "february 2023": <NewsletterFive />,
    "january 2023": <NewsletterFour />,

    "december 2022": <NewsletterThree />,
    "november 2022": <NewsletterTwo />,
    "october 2022": <NewsletterOne />,
  };
  const newsletterComponent = newsletterHash[`${month} ${year}`] || null;

  return (
    <>
      {!newsletterComponent ? (
        <>
          <MetaTags>
            <title>Newsletter | Updapt</title>
            <meta
              name="description"
              content="Keep up with the latest ESG news and climate change insights."
            />
            <meta property="og:title" content="Newsletter | Updapt" />
            <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
          </MetaTags>
          <Intro />

          <Posts newslettersData={newslettersData} handleClick={handleClick} />
          <Subscribe />
        </>
      ) : (
        <>{newsletterComponent}</>
      )}
    </>
  );
}
export default Newsletter;
