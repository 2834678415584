import { NavLink } from "react-router-dom/cjs/react-router-dom";
import React from "react";
export const trialDemo = () => {
  return (
    <div className="button-container">
      <NavLink to="/free-trial" className="nav-btn">
        Get Free Trial
      </NavLink>
      <NavLink to="/demo" className="nav-btn">
        Get Free Demo
      </NavLink>
    </div>
  );
};

export const demoTrial = () => {
  return (
    <div className="button-container">
      <NavLink to="/demo" className="nav-btn">
        Get Free Demo
      </NavLink>
      <NavLink to="/free-trial" className="nav-btn">
        Get Free Trial
      </NavLink>
    </div>
  );
};

export const buttonLink = (linkText, link) => {
  return (
    <div className="button-container">
      <NavLink to={link} className="nav-btn">
        {linkText}
      </NavLink>
    </div>
  );
};

export const getLink = (linkText, link) => {
  return (
    <NavLink to={link} className="hyper-link">
      {linkText}
    </NavLink>
  );
};

export const getImage = (imageName, alt) => {
  return (
    <div className="blog-picture">
      <img className="picture" src={require(`../../assets/Blogs/${imageName}`).default} alt={alt} />
    </div>
  );
};

export const getNewsLetter = (text = "Get Newsletter") => {
  return (
    <div className="button-container">
      <NavLink to="/resource/newsletter" className="nav-btn">
        {text}
      </NavLink>
    </div>
  );
};
