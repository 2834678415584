import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {
  getNewsLetter,
  getImage,
  getLink,
  buttonLink,
  trialDemo,
} from "../../../../Common/functions";
import structreddata from "./structureddata.json";

function BlogThirtySeven() {
  return (
    <>
      <div className="blog-thirty-seven-container">
        <MetaTags>
          <title>What is Carbon accounting</title>
          <meta
            name="description"
            content="Carbon accounting is measuring  green gas emission or carbon emission that is emitted by organizations. It includes direct and indirect emissions."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
          <script type="application/ld+json" async>
            {JSON.stringify(structreddata)}
          </script>
        </MetaTags>
        <div className="blog-title">What is Carbon Accounting?</div>
        <div className="blog-author-details">
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Manikandan S</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">October 26, 2024</div>
              <div className="blog-read-time">7 min read</div>
            </div>
          </div>
        </div>
        <p>
          <b>Table of content:</b>
          <br />

          <a href="#topic-1">Carbon accounting.</a>
          <br />

          <a href="#topic-2">What do you mean by carbon accounting?</a>
          <br />

          <a href="#topic-3">Carbon accounting with scope 1, scope 2, scope 3.</a>
          <br />

          <a href="#topic-4">How does Carbon accounting benefit businesses?</a>
          <br />

          <a href="#topic-5">What is the best Carbon Accounting Software?</a>
          <br />

          <a href="#topic-6">Best Carbon Accounting Software should possess these features.</a>
          <br />

          <a href="#topic-7">Carbon accounting methods.</a>
          <br />

          <a href="#topic-8">Carbon accounting challenges.</a>
          <br />

          <a href="#topic-9">Carbon accounting frameworks.</a>
          <br />

          <a href="#faq">FAQ</a>
        </p>

        <p>
          <h1 id="topic-1" className="paragraph-heading">
            Carbon accounting:
          </h1>
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-37-img1.png").default}
            alt="Carbon accounting"
          />
        </div>
        <p>
          Carbon accounting or green gas accounting is nothing but just measuring what percentage of
          green gas exit from the organizations business activities.
        </p>
        <p>
          The primary reason for addressing carbon emission is social responsibility as well as
          legal requirements.
        </p>
        <p>
          Carbon emission being reasons for climate change and global warming, so carbon accounting
          becomes necessary to the business.
        </p>
        <p>Businesses or industries have to submit emission reports each year</p>
        <p>
          Using carbon accounting entities can comprehensively understand where emission comes from
          and it is used also by governments, various industries for reducing carbon emission.
        </p>
        <p>
          Whereas with the help of
          <NavLink to="esg-software-for-manufacturing-industries" className="hyper-link">
            carbon accounting
          </NavLink>
          , investors precisely will understand the organization's carbon risk before they invest.{" "}
        </p>
        <p>
          Once industry will comprehend emission range, they can effectively create the target
          towards reducing.{" "}
        </p>

        {trialDemo()}

        <p>
          <h2 id="topic-2" className="paragraph-heading">
            What do you mean by carbon accounting?
          </h2>
          {getLink("Carbon accounting", "/resource/newsletter/september2023Newsletter")} is
          measuring at what volume of range greenhouse gas emissions released into the atmosphere
          through entities' business operations.
        </p>

        {getNewsLetter()}

        <p>
          <h2 id="topic-3" className="paragraph-heading">
            Carbon accounting with scope 1, scope 2, scope 3:
          </h2>
          {getImage("blog-37-img2.png", "carbon accounting emissions")}
          In {getLink("carbon accounting emissions", "/solutions/netzero")} are categorized into
          three types. Through categories of carbon emission, organizations can effectively measure
          their carbon emissions. <br />
          These are:
          <br />
          Scope 1<br />
          Scope 2<br />
          Scope 3<br />
        </p>
        <p>
          <b>Scope 1</b>
          <br />
          Scope 1 implies direct emission which means emission is created by organizations.
          <br />
        </p>

        <p>
          <div className="medium-bold">For example:</div>
          Through organization activities emissions are created.
        </p>
        <p>
          Organizations might do manufacturing processes that include steel manufacturing, cement
          production, and more. Through manufacturing emissions are created.
        </p>
        <p>
          Whereas, emissions are exited through organization controlled assets like organizations
          vehicles.{" "}
        </p>

        <p>
          <b>Scope 2</b>
          <br />
          Scope 2 implies indirect emission which means emission is created by organizations
          purchasing energy.
          <br />
          These {getLink(
            "greenhouse gasses emission",
            "what-do-net-zero-emission-targets-mean"
          )}{" "}
          not emanating from organization but organization associate with take place of emission
        </p>
        <p>
          <div className="medium-bold">For example:</div>
          Organizations run their business operations through purchased electricity but electricity
          is bought from power plants. So, purchased energy is considered as indirect emission.
        </p>

        <p>
          <b>Scope 3</b>
          <br />
          Scope 3 emission implies indirect emission which means not directly controlled by entities
          but it generated through entities activities.
          <br />
        </p>
        {buttonLink("Measure Your Supplier Emission", "/solutions/supplier-assessment")}
        <p>
          <h2 id="topic-4" className="paragraph-heading">
            How does Carbon accounting benefit businesses?{" "}
          </h2>
        </p>
        {getImage("blog-37-img3.png", "Carbon accounting benefit businesses")}
        <p>
          Carbon accounting bestows various benefits to organizations, governments along with
          providing a vivid structural approach to measure, manage and reduce carbon emission.{" "}
        </p>
        <p>
          <li className="number-type">
            {getLink("Risk management", "/solutions/enterprise-risk-management")}{" "}
          </li>
          <li className="number-type">Able to improve brand value and reputation</li>
          <li className="number-type">Cost saving</li>
          <li className="number-type">Effortlessly achieve netzero goals</li>
          <li className="number-type">Regulatory compliance</li>
          <li className="number-type">Attract the potential investors</li>
          <li className="number-type">Preparation for future market</li>
        </p>
        <p>
          <b>Risk management:</b>
        </p>
        <p>
          Carbon accounting helps entities to evaluate and identify climate risk that includes
          market risk, reputational risk and regulatory risk.{" "}
        </p>
        <p>
          If there is any climate risk in business operation and supply chain, using carbon
          accounting can develop appropriate risk mitigation strategies.{" "}
        </p>
        <p>
          <b>Able to improve brand value and reputation:</b>
        </p>
        <p>Business landscape moves into zero carbon emission.</p>
        <p>
          Strong carbon accounting efforts Differentiating your organization from other
          organizations.
        </p>
        <p>
          Consumers, product seeking owners are considering carbon accounting as a purchasing
          decision, so those who want to implement strong carbon reduction that potentially improve
          brand values . It not only purchases decisions but also attracts the customers and paves
          the way for selling their product with premium price.{" "}
        </p>
        <p>
          <b>Cost saving:</b>
          <br /> Tracking the carbon emission makes a significant advantage on energy saving that
          leads to cost saving. Carbon accounting provides you a comprehensive view of organizations
          inefficiency in business operation of transportation, production and supply chain.
        </p>
        <p>
          Through precisely identifying inefficiency areas that lead to avert unnecessary energy
          consumptions, and carbon emission.
        </p>
        <p>
          <b>Overall </b>
          <li className="number-type">Reduce water and energy consumption.</li>
          <li className="number-type">Comprehensively understand overall consumptions.</li>
          <li className="number-type"> Save operational cost.</li>
        </p>
        <p>
          <b>Effortlessly achieve netzero goals: </b>
          <br />
          Carbon accounting with reporting provides you emission information that includes indirect
          and direct emissions. With Help of the clear picture about emission can make necessary
          changes and reduce the emission and achieve climate change goals Effortlessly.
        </p>

        <p>
          <b>Regulatory compliance: </b>
          <br />
          Across the many countries measuring carbon emission becomes necessary to businesses.
          Whereas, based on geography regulation will change frequently each year. So, carbon
          accounting and reporting helps businesses to comply with these regulations.
        </p>
        <p>
          <b>Attract the potential investor:</b>
          <br />
          Investors are analyzing the organization's ESG performance through carbon accounting with
          reporting which helps investors to make decisions before investing in ESG stock.
        </p>

        <p>
          <b> Preparation for future market:</b>
          <br />
          Across the globe countries have been implementing net zero for businesses. Whereas,
          investors, consumers, and industries are insisting and prioritizing Sustainability and
          ESG. so, prepare an ESG portfolio for the future market.
        </p>
        {buttonLink("Are You Investors?", "/solutions/portfolio-assessment")}

        <p>
          <h2 id="topic-5" className="paragraph-heading">
            What is the best Carbon Accounting Software?
          </h2>
        </p>
        {getImage("blog-37-img4.png", "Carbon Accounting Software")}
        <p>
          Carbon accounting software helps entities to track, manage and calculate greenhouse gas
          emissions. Whereas, it simplifies{" "}
          {getLink("data collection", "details-about-esg-data-management-software-for-2024")} &
          management and improves ESG performance through{" "}
          {getLink("ESG reporting", "/solutions/esg-reporting")}.
        </p>
        <p>
          The best carbon accounting software should effectively manage organization carbon
          emissions and provide detailed solutions to decarbonizations. Furthermore, it should
          provide a comprehensive{" "}
          {getLink("ESG solution", "esg-software-for-small-businesses-in-2024-updapt")} based on
          industries.{" "}
        </p>

        <p>
          To meet international regulatory compliance in{" "}
          {getLink("ESG reporting", "esg-software-for-pharmaceutical-industries")}, two frameworks
          are necessary.{" "}
          <li className="number-type">
            CSRD (
            {getLink(
              "Corporate Sustainability Reporting Directive",
              "/resource/newsletter/june2024"
            )}
            )
          </li>
          <li className="number-type">
            TCFD (Task Force on Climate-related Financial Disclosures)
          </li>
          Carbon accounting platform immensely helps those who prepare ESG reporting to comply with
          international standards.
        </p>
        {buttonLink("Analyze Your ESG Process Profoundly", "/solutions/esg-analytics")}
        <p>
          <h2 id="topic-6" className="paragraph-heading">
            Best Carbon Accounting Software should possess these features.
          </h2>
        </p>
        <p>
          There are various carbon accounting software in the market but among them,{" "}
          {getLink("updapt carbon accounting software", "/")} is different and unique.
          <br />
          However,
        </p>
        <p>
          <b>Data collections:</b>
          <br />
          How do you align ESG data, if each data format is different? <br />
          How do you ensure data is quality? <br />
          Can software manage large volume data? <br />
          Software should possess features such as data quality ensure, manage large volume data and
          data normalization.
        </p>
        <p>
          <h3>ESG reporting with framework:</h3>
          {getLink(
            "ESG reporting",
            "choose-wisely-esg-and-sustainability-reporting-software-for-your-business"
          )}{" "}
          is necessary for
          <br />
          <li className="number-type">To identify risk.</li>
          <li className="number-type">To identify inefficiencies in business operations.</li>
          <li className="number-type"> To attract the investors</li>
          <li className="number-type">Save the operation cost.</li>
          Based on organizational objectives will be different. So, the platform should possess
          features to prepare the{" "}
          {getLink(
            "ESG report",
            "updapt-esg-reporting-software-for-real-estate-portfolio-and-investors"
          )}{" "}
          based on organization objectives. Furthermore, it should provide solutions for{" "}
          {getLink("ESG frameworks", "esg-frameworks-have-in-esg-reporting")}.
        </p>
        <p>
          <b>Scope emission management: </b>
          <br />
          One of the complex processes is measuring the scope emission.
        </p>
        <p>
          Scope 1 emission represents direct emission, scope 2 emission represents indirect emission
          from energy, and scope 3 emission represents indirect emission from the value chain.
        </p>
        <p>Moreover,</p>
        <p>
          <b>Decarbonization actions: </b>
          <li className="number-type">Set the target.</li>
          <li className="number-type">Suggest meaningful strategies for decarbonization.</li>
          <li className="number-type">Supply chain management.</li>
          <li className="number-type">Forecast the future emission.</li>
          Software must possess these features.
        </p>
        <p>
          <b>Risk management:</b>
          <br />
          What are Other features software should possess?
          <li className="number-type">Benchmark and target </li>
          <li className="number-type">Customization and recommendation</li>
          <li className="number-type">Portfolio management</li>
          <li className="number-type">Integration </li>
          So, {getLink("carbon accounting software", "/solutions/netzero")} should possess these
          features.
        </p>
        <p>
          <h3 id="topic-7" className="paragraph-heading">
            Carbon Accounting Methods
          </h3>
          <li className="number-type">
            <b>Spend based method</b>
          </li>
          <li className="number-type">
            <b>Activity based carbon accounting methodology</b>
          </li>
          <li className="number-type">
            <b>Hybrid method</b>
          </li>
          <li className="number-type">
            <b>GHG protocol</b>
          </li>
          {getImage("blog-37-img5.png", "Carbon Accounting Methods")}
        </p>

        <p>
          <b>Spend based method:</b>
          <br />
          Spend based emission methods evaluate green gas emission from organizations financial
          spending of goods and services.
        </p>
        <p>
          <b>How it works:</b>
          <br />
          <b>Spending information:</b>
          <br />
          Organization should assortment data expenditure of various categories that include
          transportation, raw material, etc.
        </p>
        <p>
          <b> Emission calculation:</b>
          <br />
          To calculate emissions, multiplied by the respective emission factor is the total amount
          spent in each category.
        </p>
        <p>
          <b> Advantage:</b>
          <br />
          Without numerous data gathering, it is easy to evaluate the emission because organizations
          already track financial expenditure.
        </p>
        <p>
          <b>Demetris:</b>
          <br />
          This method doesn’t provide detailed insights into emissions sources and at the same time
          less accuracy is another obstacle.
        </p>

        <p>
          <b>Activity based carbon accounting methodology: </b>
          <br />
          It method implies measuring the green gas emission through actual data such as
          transportation, water use, energy use and ect.
          <p>
            Compared to the Spend method, it processes a more accurate and detailed approach for
            emission measuring.{" "}
          </p>
        </p>
        <p>
          <b>Metris:</b>
          <br />
          With the help of comprehensive data, entities easily can develop targeted mitigation
          strategies. Whereas, this method helps to identify emission areas through sources.
        </p>
        <p>
          <b> Demetris:</b>
          <br />
          It requires various data that may be in various resources and at the same time it is
          difficult when it comes to multi-operation organization.
          <p>
            Data accuracy is deemed another Demetris of Activity based{" "}
            {getLink("carbon accounting", "/resource/newsletter/june2023Newsletter")} methodology.
          </p>
        </p>

        <p>
          <b>Hybrid method:</b>
          <br />
          Combination of spend and activity method is a hybrid carbon accounting methodology.
          <p>
            If Entities have direct data measuring carbon emission through Activity based, if they
            do not have direct data then move to the Spend method.
          </p>
        </p>

        <p>
          <b>How it works: </b>

          <p>
            Entities apply when an organization has an accurate data activity method, if they do not
            have data apply the spend method.
          </p>
        </p>

        <p>
          <b>Metris:</b>
          <p>It improves the accuracy and at the same time easily measures scope 3 emission.</p>
        </p>

        <p>
          <b>Demetris:</b>
          <p>Data management is complex.</p>
        </p>

        <p>
          <b> GHG protocol.</b>
          <p>
            Commonly used by various organizations, its emissions are divided into three types then
            measure the green gas emission.
          </p>
        </p>
        {buttonLink(
          "Facility Management case study",
          "/resource/case-study/esg-reporting-for-facility-management"
        )}

        <p>
          <h3 id="topic-8" className="paragraph-heading">
            Carbon accounting challenges:
          </h3>
        </p>
        <p>
          In today's modern business, measuring green gas emission has become necessary. Even Though
          if it is necessary, it has some challenges in carbon accounting.
          <br />
          These are:
          <li className="number-type">Data collection with accuracy</li>
          <li className="number-type">Measuring emission is intricate, especially scope 3.</li>
          <li className="number-type">Reporting requirement with standardizations.</li>
          <li className="number-type"> Data accuracy</li>
        </p>
        <p>
          <b>Data collection with accuracy: </b>
          <p>
            Measuring carbon emission requires various data such as Waste Generation data,
            Transportation Data, Employee Commuting Data, scope data, etc. <br />
            Therefore, align data based on format and consolidate are more complex.
          </p>
        </p>
        {buttonLink(
          "Waste management industry case study",
          "/resource/case-study/esg-reporting-for-waste-management-companies"
        )}
        <p>
          <b>Measuring emission is intricate, especially scope 3:</b>
          <p>
            Each industry's business operations are different, based on the type of industry that
            has various business operation
          </p>
          <p>
            So, tracking of contributing emission sources is complex and at the same time it is more
            complex when it comes, especially scope 3 emission.
          </p>
        </p>

        <p>
          <b>Reporting requirement with standardizations:</b>
          <br />
          Based on geography, the reporting requirement is different. Moreover, various
          methodologies and frameworks pave the way for confusions.
        </p>

        <p>
          <b> Data accuracy:</b>
          <br />
          Ensuring data accuracy and credibility is the primary process in carbon accounting. In
          carbon accounting need to be collected more granular data
        </p>

        {buttonLink(
          "Supplier assessment case study",
          "/resource/case-study/esg-reporting-for-supplier-assessment"
        )}

        <p>
          <h3 id="topic-9" className="paragraph-heading">
            List Out Carbon accounting frameworks
          </h3>
          <li className="number-type">Green gas protocol.</li>
          <li className="number-type">Carbon disclosure project.</li>
          <li className="number-type">Global reporting initiative.</li>
          <li className="number-type">Task force on climate related financial disclosure.</li>
          <li className="number-type"> Science based target initiative.</li>
        </p>

        <p>
          <b>To sum up:</b>
          <br />
          By adopting carbon accounting not only for climate change, it also enhances organizations
          reputations and easily obtains potential investors.
        </p>
        <br />
        <p>
          <b>
            <span className="faq37" id="faq">
              FAQ:
            </span>
          </b>
        </p>
        <p>
          <h2>How do you calculate carbon accounting?</h2>
          Carbon accounting is nothing but measuring What amount of green gas emission is emitted by
          organizations.
        </p>

        <p>
          <h2>Why is carbon accounting difficult?</h2>
          Carbon accounting is a challenge due to data collection, industry type, and reporting.
        </p>
      </div>
    </>
  );
}

export default BlogThirtySeven;
