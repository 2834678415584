/** @format */

import React from "react";
import Blogs from "../Blogs";
import Newsletter from "../Newsletter";
import Media from "../Media";
import CaseStudy from "../CaseStudy";
import Newsroom from "../Newsroom";
import { Route } from "react-router-dom";
import BlogOne from "../Blogs/BlogOne";
import BlogTwo from "../Blogs/BlogTwo";
import BlogThree from "../Blogs/BlogThree";
import BlogFour from "../Blogs/BlogFour";
import BlogFive from "../Blogs/BlogFive";
import BlogSix from "../Blogs/BlogSix";
import BlogSeven from "../Blogs/BlogSeven";
import BlogEight from "../Blogs/BlogEight";
import BlogNine from "../Blogs/BlogNine";
import BlogTen from "../Blogs/BlogTen";
import BlogEleven from "../Blogs/BlogEleven";
import BlogTwelve from "../Blogs/BlogTwelve";
import BlogThirteen from "../Blogs/BlogThirteen";
import BlogFourteen from "../Blogs/BlogFourteen";
import BlogFifteen from "../Blogs/BlogFifteen";
import BlogSixteen from "../Blogs/BlogSixteen";
import BlogSeventeen from "../Blogs/BlogSeventeen";
import BlogEighteen from "../Blogs/BlogEighteen";
import BlogNineteen from "../Blogs/BlogNineteen";
import BlogTwenty from "../Blogs/BlogTwenty";
import BlogTwentyOne from "../Blogs/BlogTwentyOne";
import NewsroomMediaOne from "../Newsroom/NewsroomMediaOne";
import BlogTwentyTwo from "../Blogs/BlogTwentyTwo";
import BlogTwentyThree from "../Blogs/BlogTwentyThree";
import Podcast from "../Podcast";
import PodcastInfoContainer from "../Podcast/containers/PodcastInfoContainer";
import BlogTwentyFour from "../Blogs/BlogTwentyFour";
import BlogTwentyFive from "../Blogs/BlogTwentyFive";
import BlogTwentySix from "../Blogs/BlogTwentySix";
import BlogTwentySeven from "../Blogs/BlogTwentySeven";
import BlogTwentyEight from "../Blogs/BlogTwentyEight";
import BlogTwentyNine from "../Blogs/BlogTwentyNine";
import BlogThirty from "../Blogs/BlogThirty";
import BlogThirtyOne from "../Blogs/BlogThirtyOne";
import BlogThirtyTwo from "../Blogs/BlogThirtyTwo";
import BlogThirtyThree from "../Blogs/BlogThirtyThree";
import NewsroomMediaTwo from "../Newsroom/NewsroomMediaTwo";
import BlogThirtyFour from "../Blogs/BlogThirtyFour";
import BlogThirtyFive from "../Blogs/BlogThirtyFive";
import BlogThirtySix from "../Blogs/BlogThirtySix";
import BlogThirtySeven from "../Blogs/BlogThirtySeven";
import BlogThirtyEight from "../Blogs/BlogThirtyEight";
import BlogThirtyNine from "../Blogs/BlogThirtyNine";

function Resource({ newslettersData, mediaData, podcastData }) {
  return (
    <>
      <Route path="/resource/blogs" exact>
        <Blogs blogsData={newslettersData} />
      </Route>
      <Route path="/resource/newsletter" exact>
        <Newsletter newslettersData={newslettersData}></Newsletter>
      </Route>
      <Route path="/resource/media" exact>
        <Media mediaData={mediaData}></Media>
      </Route>
      <Route path="/resource/newsroom" exact>
        <Newsroom mediaData={mediaData}></Newsroom>
      </Route>
      <Route path="/resource/podcast" exact>
        <Podcast podcastData={podcastData}></Podcast>
      </Route>
      <Route path="/resource/case-study" exact>
        <CaseStudy newslettersData={newslettersData}></CaseStudy>
      </Route>
      <Route path="/resource/podcast/:info" exact>
        <PodcastInfoContainer />
      </Route>
      <Route path="/resource/blogs/what-do-net-zero-emission-targets-mean">
        <BlogOne></BlogOne>
      </Route>
      <Route path="/resource/blogs/the-need-for-a-sustainable-business-behavior">
        <BlogTwo></BlogTwo>
      </Route>
      <Route path="/resource/blogs/why-diversity-in-integral-to-a-sustainable-business">
        <BlogThree></BlogThree>
      </Route>
      <Route path="/resource/blogs/sustainability-and-the-pandemic">
        <BlogFour></BlogFour>
      </Route>
      <Route path="/resource/blogs/world-environment-day">
        <BlogFive></BlogFive>
      </Route>
      <Route path="/resource/blogs/sea-level-rise-and-it-s-impact">
        <BlogSix></BlogSix>
      </Route>
      <Route path="/resource/blogs/the-road-to-sustainable-development-how-the-sdgs-are-reinforced-in-india">
        <BlogSeven></BlogSeven>
      </Route>
      <Route path="/resource/blogs/what-happens-when-we-discard-our-phone-for-a-smarter-one">
        <BlogEight></BlogEight>
      </Route>
      <Route path="/resource/blogs/sustainability-and-the-fashion-industry">
        <BlogNine></BlogNine>
      </Route>
      <Route path="/resource/blogs/responsible-companies-and-a-responsible-nation-an-insight-to-csr-in-india">
        <BlogTen></BlogTen>
      </Route>
      <Route path="/resource/blogs/sustainability-let-s-make-it-better">
        <BlogEleven></BlogEleven>
      </Route>
      <Route path="/resource/blogs/sdgs-101">
        <BlogTwelve></BlogTwelve>
      </Route>
      <Route path="/resource/blogs/are-evs-the-way-of-the-future">
        <BlogThirteen></BlogThirteen>
      </Route>
      <Route path="/resource/blogs/debunking-the-myths">
        <BlogFourteen></BlogFourteen>
      </Route>
      <Route path="/resource/blogs/Understanding-the-greenhouse-gases">
        <BlogFifteen></BlogFifteen>
      </Route>
      <Route path="/resource/blogs/Let's-talk-about-Carbon-Taxes">
        <BlogSixteen></BlogSixteen>
      </Route>
      <Route path="/resource/blogs/Understanding-the-Greenhouse-Gases-CH4">
        <BlogSeventeen></BlogSeventeen>
      </Route>
      <Route path="/resource/blogs/Extreme-weather-events-and-how-they-are-linked-to-Climate-Change">
        <BlogEighteen></BlogEighteen>
      </Route>
      <Route path="/resource/blogs/understanding-climate-justice">
        <BlogNineteen></BlogNineteen>
      </Route>
      <Route path="/resource/blogs/let's-talk-about-biofuels">
        <BlogTwenty />
      </Route>
      <Route path="/resource/blogs/where-does-the-world-stand-with-sdg-2030-today">
        <BlogTwentyOne />
      </Route>
      <Route path="/resource/newsroom/dnv-scpa-collaborates-with-updapt-an-esg-tech-co-to-transform-your-sustainability-journey-230553">
        <NewsroomMediaOne />
      </Route>
      <Route path="/resource/blogs/importance-of-an-esg-report-for-a-better-esg-management" exact>
        <BlogTwentyTwo />
      </Route>
      <Route path="/resource/blogs/how-can-we-achieve-decarbonization" exact>
        <BlogTwentyThree />
      </Route>
      <Route path="/resource/blogs/benefits-of-impact-investment" exact>
        <BlogTwentyFour />
      </Route>
      <Route path="/resource/blogs/internal-carbon-pricing" exact>
        <BlogTwentyFive />
      </Route>
      <Route path="/resource/blogs/different-terms-but-the-goal-is-lets-go-net-zero" exact>
        <BlogTwentySix />
      </Route>
      <Route path="/resource/blogs/the-importance-of-integrating-dei" exact>
        <BlogTwentySeven />
      </Route>
      <Route path="/resource/blogs/updapt-esg-all-in-One-sustainability-software" exact>
        <BlogTwentyEight />
      </Route>
      <Route path="/resource/blogs/details-about-esg-data-management-software-for-2024" exact>
        <BlogTwentyNine />
      </Route>{" "}
      <Route path="/resource/blogs/transition-pathway-initiative" exact>
        <BlogThirty />
      </Route>{" "}
      <Route path="/resource/blogs/esg-software-for-small-businesses-in-2024-updapt" exact>
        <BlogThirtyOne />
      </Route>{" "}
      <Route
        path="/resource/blogs/choose-wisely-esg-and-sustainability-reporting-software-for-your-business"
        exact>
        <BlogThirtyTwo />
      </Route>{" "}
      <Route
        path="/resource/blogs/updapt-esg-reporting-software-for-real-estate-portfolio-and-investors"
        exact>
        <BlogThirtyThree />
      </Route>{" "}
      <Route
        path="/resource/newsroom/updapt-an-esg-tech-co-partners-with-grant-thornton-advisory-east-africa"
        exact>
        <NewsroomMediaTwo />
      </Route>
      <Route path="/resource/blogs/esg-software-for-manufacturing-industries" exact>
        <BlogThirtyFour />
      </Route>
      <Route path="/resource/blogs/esg-software-for-pharmaceutical-industries" exact>
        <BlogThirtyFive />
      </Route>
      <Route path="/resource/blogs/esg-frameworks-have-in-esg-reporting" exact>
        <BlogThirtySix />
      </Route>
      <Route path="/resource/blogs/what-is-carbon-accounting" exact>
        <BlogThirtySeven />
      </Route>
      <Route path="/resource/blogs/minimize-the-environmental-impact-of-e-commerce-logistics" exact>
        <BlogThirtyEight />
      </Route>
      <Route path="/resource/blogs/esg-data-management-software-for-uae" exact>
        <BlogThirtyNine />
      </Route>
    </>
  );
}
export default Resource;
