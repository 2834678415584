import React from "react";
import assets from "../../../shared/constants/assetVariables";

function OurTeam({ teamMembersOne, teamMembersTwo, teamMembersThree }) {
  return (
    <>
      <div className="our-team-container">
        {teamMembersOne.map((teamMember, index) => (
          <div className="our-team-wrapper" key={teamMember.name.toString()}>
            <div className="our-team-card">
              <div className="team-images">
                <img
                  src={teamMember.profilePic}
                  alt={teamMember.name}
                  className="image-icon"
                  width="100%"
                  height="100%"
                />
                <div className="team-social-icon">
                  <a
                    className="social-linkedin"
                    href={teamMember.linkedInUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img src={assets.icons.linkedLogo} alt="linkedin" className="image-icon" />
                  </a>
                </div>
              </div>
              <h1 className="team-member-name">{teamMember.name}</h1>
              <p className="team-member-role">{teamMember.role}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="our-team-container">
        {teamMembersTwo &&
          teamMembersTwo.map((teamMember, index) => (
            <div className="our-team-wrapper" key={teamMember.name.toString()}>
              <div className="our-team-card">
                <div className="team-images">
                  <img src={teamMember.profilePic} alt={teamMember.name} className="image-icon" />
                  <div className="team-social-icon">
                    <a
                      className="social-linkedin"
                      href={teamMember.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src={assets.icons.linkedLogo} alt="linkedin" className="image-icon" />
                    </a>
                  </div>
                </div>
                <h1 className="team-member-name">{teamMember.name}</h1>
                <p className="team-member-role">{teamMember.role}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="our-team-container">
        {teamMembersThree &&
          teamMembersThree.map((teamMember, index) => (
            <div className="our-team-wrapper" key={teamMember.name.toString()}>
              <div className="our-team-card">
                <div className="team-images">
                  <img src={teamMember.profilePic} alt={teamMember.name} className="image-icon" />
                  <div className="team-social-icon">
                    <a
                      className="social-linkedin"
                      href={teamMember.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src={assets.icons.linkedLogo} alt="linkedin" className="image-icon" />
                    </a>
                  </div>
                </div>
                <h1 className="team-member-name">{teamMember.name}</h1>
                <p className="team-member-role">{teamMember.role}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default OurTeam;
